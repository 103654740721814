
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mont', sans-serif;
}

body {
    background-color: #fff;
}

.landing-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 180px);
    min-height: 650px;
    box-shadow: 0 15px 35px rgba(255, 255, 255, 0.05);
    overflow: hidden;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
}

.textContainer {
    width: 70%;
}

@media (max-width: 900px) {
    .textContainer {
        width: 100%;
    }
}

@media (min-width: 2000px){
    .landing-container {
        height: calc(100vh - 210px);
        min-height: 650px;
    }
    
}

.landing-container h1 {
    text-align: left;
    margin-bottom: 3vh;
    font-size: 2.5rem;
    display: flex;
    justify-content: flex-start;
}

.landing-container p {
    font-size: 1.1rem;
}
.container {
    display: flex;
    flex-direction: row;
}

