.ant-picker-calendar-date-value {
    visibility: hidden;
}
 
.ant-picker-calendar-date-today {
    color: #964cff;
}


:where(.css-dev-only-do-not-override-ixblex).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-calendar-date {
    background-color: #c7d3e288;
}

:where(.css-dev-only-do-not-override-ixblex).ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today {
    border-color: #964cff;
}

:where(.css-dev-only-do-not-override-ixblex).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border-color: #964cff;
}

:where(.css-dev-only-do-not-override-ixblex).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #bb2cc0;
}
:where(.css-dev-only-do-not-override-ixblex).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
:where(.css-dev-only-do-not-override-ixblex).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: #964cff;
}

