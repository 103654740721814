
.navbar-brand img {
    position: absolute;
    width: 189px;
    height: 100px;
    left: 20px;
    top: 18px;
}

.navbar-expand-lg .navbar-nav .nav-link {
    height: 19px;
    margin-top: 50px;
    margin-right: 32px;
    color: #fff;
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
}

.navbar-expand-lg .navbar-nav .nav-link.active-link {
    color: var(--primary-color);
}


/*Responsiveness for screen sizes starting from 260px to 320px*/
@media screen and (max-width: 320px) {

    .container {
        width: 100%;
    }

    .header {
        height: 35px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        left: 2px;
        width: 80px;
        height: 35px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 30px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }   
}


/*  Responsiveness for screen sizes starting from 300px to 450px */
@media screen and (min-width: 320px) and (max-width: 320px) {

    .container {
        width: 100%;
    }

    .header {
        height: 45px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        left: 3px;
        width: 90px;
        height: 50px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 20px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }
}


/*Responsiveness for screen sizes starting from 260px to 320px*/
@media screen and (min-width: 260px) and (max-width: 320px) {

    .container {
        width: 100%;
    }

    .header {
        height: 40px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        left: 2px;
        width: 80px;
        height: 40px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 30px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }   
}


/*  Responsiveness for screen sizes starting from 300px to 450px */
@media screen and (min-width:300px) and (max-width: 450px) {

    .container {
        width: 100%;
    }

    .header {
        height: 90px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        left: 3px;
        width: 90px;
        height: 50px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 50px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }
}

/*  Responsiveness for screen sizes starting from 451px to 600px */
@media screen and (min-width: 451px) and (max-width: 600px) {

    .container {
        width: 100%;
    }

    .header {
        height: 80px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        left: 3px;
        width: 110px;
        height: 60px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 60px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }
}

/*  Responsiveness for screen sizes starting from 601px to 750px */
@media screen and (min-width: 601px) and (max-width: 750px) {

    .container {
        height: 80px;
        width: 100%;
    }

    .header {
        height: 110px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        /* left: 3px; */
        width: 110px;
        height: 55px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 60px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }
}

/*  Responsiveness for screen sizes starting from 751px to 950px */
@media screen and (min-width: 751px) and (max-width: 950px) {

    .container {
        width: 100%;
    }

    .header {
        height: 120px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        left: 2px;
        width: 120px;
        height: 70px;
    }

    .navbar-toggler-icon {
        position: absolute;
        top: 15px;
    }

    .ms-auto  {
        position: absolute;
        right: 0;
        margin-top: 20px;
        /* float: right; */
        color: #000;
        z-index: 100;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        color: #000;
        font-size: 14px;
        margin-top: 2px;
        margin-left: 8px;
        margin-bottom: 0px;
    }

    .nav-item {
        background-color: rgb(238, 234, 234);
        height: 50px;
    }

    .navbar-expand-lg[_ngcontent-hqf-c53], .navbar-nav[_ngcontent-hqf-c53], .nav-link[_ngcontent-hqf-c53] {
        /* height: 19px; */
        /* margin-top: 50px; */
        /* margin-right: 32px; */
        margin-left: 12px;
        color: #fff;
        font-family: 'Inter';
        font-size: 17px;
        font-weight: 400;
        font-style: normal;
    }
}

/*  Responsiveness for screen sizes starting from 951px to 1280px */
@media screen and (min-width: 951px) and (max-width: 1280px) {

    .container {
        width: 100%;
    }

    .header {
        height: 120px;
    }

    .navbar-brand img {
        position: absolute;
        top: 10px;
        /* left: 2px; */
        width: 125px;
        height: 75px;
    }

    .navbar-toggler-icon {
        position: absolute;
        top: 15px;
    }
}

  